import React from 'react'
import Header from './header'
import Footer from './footer'
import '../styles/layout.css'
import '../styles/components.css'
import styles from './layout.module.css'

const Layout = ({children, onHideNav, onShowNav, showNav, siteTitle, navigationNodes, footerNodes}) => (
  <>
    <Header siteTitle={siteTitle} onHideNav={onHideNav} onShowNav={onShowNav} showNav={showNav} navigationNodes={navigationNodes} />
    <div className={styles.content}>{children}</div>
    <Footer footerNodes={footerNodes} />
  </>
)



export default Layout
