import {graphql, StaticQuery} from 'gatsby'
import React, {useState} from 'react'
import Layout from '../components/layout'
import {mapEdgesToNodes} from '../lib/helpers'

const query = graphql`
  query SiteTitleQuery {
    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      title
    }
    nav: sanityNavigation {
      navigationElements {
        title
        _key
        reference {
          id
          slug {
            current
          }
        }
      }
    }
    footer: allSanityFooter(
      sort: { fields: [_createdAt], order: ASC}
    ) {
      edges {
        node {
          id
          publishedAt
          title
          _rawBody(resolveReferences: {maxDepth: 5})
        }
      }
    }
  }
`

function LayoutContainer (props) {
  const [showNav, setShowNav] = useState(false)
  function handleShowNav () {
    setShowNav(true)
  }
  function handleHideNav () {
    setShowNav(false)
  }



  return (
    <StaticQuery
      query={query}
      render={data => {
        if (!data.site) {
          throw new Error(
            'Missing "Site settings". Open the Studio at http://localhost:3333 and some content in "Site settings"'
          )
        }
        return (
          <Layout
            {...props}
            showNav={showNav}
            siteTitle={data.site.title}
            onHideNav={handleHideNav}
            onShowNav={handleShowNav}
            navigationNodes={data.nav.navigationElements}
            footerNodes={data.footer.edges}
          />
        )
      }}
    />
  )
}

export default LayoutContainer
