import {Link} from 'gatsby'
import React from 'react'
import Icon from './icon'
import {cn} from '../lib/helpers'

import styles from './header.module.css'


  const Header = ({onHideNav, onShowNav, showNav,  siteTitle, navigationNodes}) => (

    <div className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.branding}>
          <Link to='/'>{siteTitle}</Link>
        </div>

        <button className={styles.toggleNavButton} onClick={showNav ? onHideNav : onShowNav}>
          <Icon symbol='hamburger' />
        </button>
        <nav className={cn(styles.nav, showNav && styles.showNav)}>
          <ul>
            {navigationNodes.map((node) => {
              return (
                <li key={node._key}>
                  <Link to={node.reference.slug.current}>{node.title}</Link>
                </li>
              )
            })}
          </ul>
        </nav>
      </div>
    </div>
  )


export default Header
