import {Link} from 'gatsby'
import React from 'react'
import FooterElement from './footer-element'
import PortableText from './portableText'
import {buildImageObj, cn} from '../lib/helpers'
import {responsiveTitle3} from './typography.module.css'
import styles from './footer-wrapper.module.css'
import elementstyles from './footer-element.module.css'

  const Footer = ({footerNodes}) => (

    <div className={styles.root}>
      <ul className={styles.grid}>
      {footerNodes.map((element) => {
        return (
          <li key={element.node.id}>
            <h3 className={cn(responsiveTitle3, elementstyles.title)}>{element.node.title}</h3>
            <div className={elementstyles.text}>
              {element.node._rawBody && <PortableText blocks={element.node._rawBody} />}
            </div>
          </li>
        )
      })}
      </ul>
    </div>
  )

export default Footer
