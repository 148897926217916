import {format, distanceInWords, differenceInDays} from 'date-fns'
import {Link} from 'gatsby'
import React from 'react'
import {buildImageObj, cn} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import PortableText from './portableText'

import styles from './footer-element.module.css'
import {responsiveTitle3} from './typography.module.css'

function FooterElement (props) {
  const {_rawBody, title, publishedAt} = props
  return (
    <div className={props.isInList ? styles.inList : styles.inGrid}>
      <h3 className={cn(responsiveTitle3, styles.title)}>{props.title}</h3>
      <div className={styles.text}>
        {_rawBody && <PortableText blocks={_rawBody} />}
      </div>
    </div>
  )
}

export default FooterElement
